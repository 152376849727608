// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-post-layout-js": () => import("/opt/build/repo/src/layouts/postLayout.js" /* webpackChunkName: "component---src-layouts-post-layout-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artykuly-js": () => import("/opt/build/repo/src/pages/artykuly.js" /* webpackChunkName: "component---src-pages-artykuly-js" */),
  "component---src-pages-artykuly-dla-biznesu-js": () => import("/opt/build/repo/src/pages/artykuly/dla-biznesu.js" /* webpackChunkName: "component---src-pages-artykuly-dla-biznesu-js" */),
  "component---src-pages-artykuly-dla-biznesu-pierwsze-kroki-js": () => import("/opt/build/repo/src/pages/artykuly/dla-biznesu/pierwsze-kroki.js" /* webpackChunkName: "component---src-pages-artykuly-dla-biznesu-pierwsze-kroki-js" */),
  "component---src-pages-artykuly-dla-kazdego-js": () => import("/opt/build/repo/src/pages/artykuly/dla-kazdego.js" /* webpackChunkName: "component---src-pages-artykuly-dla-kazdego-js" */),
  "component---src-pages-artykuly-dla-kazdego-pierwsze-kroki-js": () => import("/opt/build/repo/src/pages/artykuly/dla-kazdego/pierwsze-kroki.js" /* webpackChunkName: "component---src-pages-artykuly-dla-kazdego-pierwsze-kroki-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-autorze-js": () => import("/opt/build/repo/src/pages/o-autorze.js" /* webpackChunkName: "component---src-pages-o-autorze-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("/opt/build/repo/src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-regulamin-js": () => import("/opt/build/repo/src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-slownik-js": () => import("/opt/build/repo/src/pages/slownik.js" /* webpackChunkName: "component---src-pages-slownik-js" */),
  "component---src-pages-zasady-wielopokoleniowych-js": () => import("/opt/build/repo/src/pages/zasady-wielopokoleniowych.js" /* webpackChunkName: "component---src-pages-zasady-wielopokoleniowych-js" */)
}

